@font-face {
  font-family: "HumanSans";
  font-weight: 500;
  src: local("Lato"), url(./fonts/HumanSans-Medium.otf) format("opentype");
}

@font-face {
  font-family: "HumanSans";
  src: local("Lato"), url(./fonts/HumanSans-Regular.otf) format("opentype");
}

body {
  padding: 0;
  margin: 0;
  font-family: HumanSans, cursive;
}
.App {
  font-family: HumanSans;
  text-align: center;
}

.skeleton {
  background-color: rgba(240, 240, 240, 1);
  background-image: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1),
    rgba(255, 255, 255, 1),
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 500ms ease-in-out infinite;

  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5%;
  padding-right: 5%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.nav-bar__button {
  width: 182px;
  height: 48px;
  background: #0070f0;
  border-radius: 16px;
  border: none;
  color: white;
  font-family: HumanSans;
}

.nav-bar__logo {
  font-style: normal;
  font-weight: normal;
  font-size: 43.6872px;
  line-height: 52px;
  /* identical to box height */

  color: #000000;
}

.side_bar__header {
  font-weight: normal;
  font-size: 2.5rem;
  letter-spacing: -0.015em;
  color: #0070f0;
  width: 70%;
}

.section-container {
  padding: 6%;
  text-align: left;
}

.sequence-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.base-container {
  height: 100%;
  width: 100%;
}

.blue-base-container::after { 
  background-color: rgba(0, 0, 0, 0.37);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 250px;
  bottom: 0;
  z-index: -1;
  background: rgb(145, 176, 206);
}

.green-base-container::after {
  background: rgb(98, 139, 109);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 250px;
  bottom: 0;
  z-index: -1;
}

.sequence-copy {
  position: absolute;
  z-index: 10;
  top: 110px;
  left: 60px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: self-start;
}

.sequence-copy span {
  font-size: 50px;
  padding-left: 8px;
}

.sequence-copy span:last-of-type {
  font-weight: bold;
  font-size: 180px;
  padding-left: 0;
  line-height: .9;
}

.brand_details__content {
  display: flex;
  padding-bottom: 120px;
}

.brand_details__content--text {
  font-size: 18px;
  line-height: 30px;
}

.brand_details__content--center {
  padding-left: 4%;
  padding-right: 4%;
}

.partners__header {
  color: #0070f0;
  font-size: 60px;
  margin: 0;
  padding-bottom: 45px;
}

.partners__header--container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.footer {
  background-color: #0070f0;
  height: 200px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
  padding-left: 5%;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partners_details__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partners_details__content.stage {
  justify-content: space-between;
}

.partners_details__content--item {
  flex: 0 0 50%;
  /* padding: 20px 0; */
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.partners_details__content--item img {
  width: 90px;
  height: 50px;
  object-fit: contain;
  mix-blend-mode: multiply;
}

.sequence-container__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5000;
  opacity: 0.4;
}

.sequence-container {
  width: 100%;
  height: 100%;
}

.partners-report__button {
  cursor: pointer;
  width: 263px;
  height: 53px;
  background: #0070f0;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid transparent;
}

.partners-report__button span {
  padding-left: 10px;
}

.partners-report__button:hover{
  background: #fff;
  color: #0070F0;
  border: 1px solid #0070F0;
}

.partners-report__button:hover path {
  fill: #0070F0;
}

@media (min-width: 700px) {
  .partners_details__content--item {
    flex: 0 0 15%;
  }

  .stage.partners_details__content--item {
    flex: 0 0 16%;
  }

  .partners_details__content--item img {
    width: 130px;
    height: 65px;
  }

  .stage .partners_details__content--item:last-of-type img {
    width: 260px;
  }
}

.blue-base {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: -2;
  background: #D3DBE6;
}

.green-base {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: -2;
  background: #FFF8BE;
}

.app-portrait {
  display: none;
}

@media (max-width: 850px) {
  .brand_details__content {
    flex-direction: column;
  }

  .brand_details__content--center {
    padding: 4% 0;
  }

  .side_bar__header {
    width: 100%;
  }

  .sequence-copy span {
    font-size: 18px;
    padding-left: 2px;
  }
  
  .sequence-copy span:last-of-type {
    font-weight: bold;
    font-size: 60px;
    padding-left: 0;
  }

  .partners__header {
    font-size: 30px;
  }

  .blue-base-container::after,
  .green-base-container::after {
    height: 150px;
  }
}

@media (max-width: 600px) {
  .App {
    display: none;
  }

  .app-portrait {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100vh;
  }

  .app-portrait img {
    width: 40%;
  }
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px)
  and (orientation: landscape) { 
    .App {
      display: block;
    }
}
